var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"approval-detail-view"},[_c('section',{staticClass:"detail-comment"},[_c('div',{staticClass:"inner-box"},[_c('div',{staticClass:"comment-left"},[(_vm.type !== 'channel')?_c('user-card',{attrs:{"obj":{
            avatar: _vm.initiateObj.avatar,
            name: _vm.initiateObj.promoterName,
            job: _vm.initiateObj.promoterOrgChain,
            userId: _vm.initiateObj.promoterId
          }},scopedSlots:_vm._u([{key:"reward",fn:function(){return [(_vm.initiateObj.processState === 6)?_c('span',{staticClass:"reward"},[_vm._v("奖励"+_vm._s(_vm.initiateObj.finalScore || 0)+"分")]):_vm._e()]},proxy:true},{key:"status",fn:function(){return [_c('div',{staticClass:"status-box"},[_c('span',{staticClass:"num"},[_vm._v("NO."+_vm._s(_vm.initiateObj.contributeCode || '暂无'))]),_c('div',[_c('span',{staticClass:"status",class:("status-" + (_vm.initiateObj.processState))},[_vm._v(_vm._s(_vm._f("formateProcessState")(_vm.initiateObj.processStateName,_vm.initiateObj.approverName, _vm.initiateObj.processState)))]),_c('span',{staticClass:"instructions",on:{"click":function($event){_vm.showDialog = true}}},[_vm._v("审批说明")])])])]},proxy:true}],null,false,108889007)}):_c('div',{staticClass:"list-code"},[_c('span',{staticClass:"num"},[_vm._v("NO."+_vm._s(_vm.initiateObj.contributeCode || '暂无'))])]),_c('div',{staticClass:"item-box"},_vm._l((_vm.formSelList),function(item,indexC){return _c('div',{directives:[{name:"show",rawName:"v-show",value:(item.code),expression:"item.code"}],key:indexC,staticClass:"application"},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(item.title))]),_c('div',{staticClass:"value"},[_vm._v(_vm._s(item.value))])])}),0),_c('div',{staticClass:"form-box"},_vm._l((_vm.formList),function(item,index){return _c('div',{directives:[{name:"show",rawName:"v-show",value:(item.code),expression:"item.code"}],key:index,staticClass:"item"},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(item.title))]),_c('p',{staticClass:"value"},[_vm._v(_vm._s(item.value))])])}),0),(_vm.fileObj.code && _vm.fileList.length > 0)?_c('div',{staticClass:"file-box"},[_c('div',{staticClass:"file-title"},[_vm._v(_vm._s(_vm.fileObj.title || '上传文件'))]),_vm._l((_vm.fileList),function(file,index){return _c('tojoy-file',{key:index,staticClass:"file-item",attrs:{"data":{
              type: file.fileSuffix,
              fileName: file.fileName || '暂无',
              url: file.filePath
            }}})})],2):_vm._e()],1),(_vm.type !== 'channel')?_c('div',{staticClass:"comment-right"},[_c('div',{staticClass:"title"},[_vm._v(" 流程记录 ")]),_c('record-list',{attrs:{"list":_vm.activities,"score":_vm.score}})],1):_vm._e()])]),(_vm.type === 'initiate' || (_vm.type === 'approval' && _vm.initiateObj.isOpen))?_c('div',{staticClass:"result-bottom"},_vm._l((_vm.btnConfig),function(item,index){return _c('el-button',{directives:[{name:"show",rawName:"v-show",value:(item.show),expression:"item.show"}],key:index,class:item.className,attrs:{"type":item.type},on:{"click":function($event){return item.fn(item.code)}}},[_vm._v(_vm._s(item.text))])}),1):_vm._e(),_c('approvalExplanation',{attrs:{"showDialog":_vm.showDialog,"modelTyle":"team"}}),_c('pass',{attrs:{"showDialog":_vm.showPassDialog,"passObj":_vm.passObj,"moduleName":_vm.moduleName}}),_c('entrust',{attrs:{"showDialog":_vm.showEntrustDialog,"entrustObj":_vm.entrustObj,"moduleName":_vm.moduleName}}),_c('rejected',{attrs:{"showDialog":_vm.showRejectedDialog,"rejectObj":_vm.rejectObj,"moduleName":_vm.moduleName}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }