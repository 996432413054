import { btnConfig } from '@/components/initiate/state.js'
import pass from '@/components/initiate/pass-dialog'
import rejected from '@/components/initiate/rejected-dialog'
import entrust from '@/components/initiate/entrust-dialog'
import userCard from '@/components/initiate/user-card'
import recordList from '@/components/initiate/record-list'
import approvalExplanation from '@/components/initiate/approval-explanation'
import TojoyFile from '@/components/common/file'
export default {
  components: {
    userCard,
    approvalExplanation,
    TojoyFile,
    pass,
    rejected,
    entrust,
    recordList
  },
  props: {
    id: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'initiate' //initiate 我发起的 approval 我审批的 channel 从首页进来的
    },
    modelCode: {
      type: String,
      default: 'innovate' //innovate 创新成果 team 团队贡献
    },
    moduleName: {
      type: String,
      default: 'all'
    }
  },
  data() {
    return {
      btnConfig: btnConfig,
      rejectObj: {}, //驳回参数
      passObj: {}, //通过参数
      entrustObj: {}, //委托他人
      score: '', //最后得分
      showDialog: false,
      showEntrustDialog: false, //委托他人
      showRejectedDialog: false, //驳回
      showPassDialog: false, //通过弹框
      initiateObj: {},
      userObj: {},
      fileObj: {
        title: '上传文件',
        code: null,
        cname: 'innovateAnnexListFormName'
      },
      fileList: [],
      activities: []
    }
  },
  filters: {
    formateProcessState(name, val, code) {
      //审批中-xx 已驳回-xx
      //提交 1 已撤销 2  待审批 3
      //审批中 4 驳回 5  审批通过 6
      if ([3, 4, 5].includes(code)) {
        return `${name}-${val}`
      } else {
        return `${name}`
      }
    }
  },
  methods: {
    handleSetProcesData(finalScoreStr, finalScore) {
      this.score = finalScoreStr
      this.activities.forEach(el => {
        //operationStateName 状态name
        // operationState	状态值,1:发起,2:已撤销,3:待审批,4:审批中,5:驳回,6:审批通过,7:委托,9:重新提交
        el.case = el.operationStateName
        switch (el.operationState) {
          case 1:
            el.case = ''
            break
          case 5:
            //驳回,撤销
            el.case = `${el.operationStateName}${el.remarks ? ',' : ''}${el.remarks || ''}`
            break
          case 4:
            //审批通过 resultLevel scoreStr
            el.case = `${el.operationStateName}${el.scoreStr ? ',' : ''}${el.scoreStr || ''}${
              el.remarks ? ',' : ''
            }${el.remarks || ''}`
            break
          case 6:
            //审批通过 resultLevel finalScore
            el.case = `${el.operationStateName}${finalScore ? ',+' : ''}${finalScore}分${
              el.remarks ? ',' : ''
            }${el.remarks || ''}`
            break
          case 7:
            //委托
            el.case = el.operationDesc
            break
        }
      })
    },
    handlesetDetail(obj, selObj) {
      for (let key in obj) {
        this.formList.forEach(el => {
          if (el.cname === key) {
            el.value = obj[key] ? obj[key] : ''
            el.title = obj[key] ? this.initiateObj[key + 'FormName'] : ''
            el.code = obj[key] ? this.initiateObj[key + 'FormCode'] : null
          }
        })
      }
      for (let key in selObj) {
        this.formSelList.forEach(el => {
          if (el.cname === key) {
            el.value = selObj[key] ? selObj[key] : ''
            if (el.cname === 'proposalDate') {
              el.title = selObj[key] ? this.initiateObj[key + 'FormName'] : ''
              el.code = selObj[key] ? this.initiateObj[key + 'FormCode'] : null
            } else {
              let keys =
                el.cname === 'implementOrgName'
                  ? key.substring(0, key.length - 4) + 'Id'
                  : key.substring(0, key.length - 4)
              el.title = selObj[key] ? this.initiateObj[keys + 'FormName'] : ''
              el.code = selObj[key] ? this.initiateObj[keys + 'FormCode'] : null
            }
          }
        })
      }
    },
    handleSetDialog(code, innovateLevelName, innovateLevel) {
      //审批状态 5 驳回  reject  4通过pass
      if (code === 'reject') {
        this.showRejectedDialog = true
        this.rejectObj = {
          processExampleId: this.initiateObj.processExampleId,
          innovateCode: this.id
        }
      } else if (code === 'pass') {
        this.showPassDialog = true
        this.passObj = {
          processExampleId: this.initiateObj.processExampleId,
          innovateCode: this.id,
          resultLevel: this.initiateObj?.resultLevel ?? '',
          score: this.initiateObj?.finalScore ?? null,
          effectId: innovateLevel,
          innovateLevelName: innovateLevelName
        }
      } else {
        this.showEntrustDialog = true
        this.entrustObj = {
          processExampleId: this.initiateObj.processExampleId,
          innovateCode: this.id
        }
      }
    },
    initBtnFn(handleMessageBox, handleEdit, handleDialog) {
      this.btnConfig.forEach(el => {
        let show = false
        let fn = null
        if (el.flag === 1) {
          let rs = el.state.includes(this.initiateObj.processState)
          show = this.type === 'initiate' && rs
          fn = handleMessageBox
          if (el.code === 'edit') {
            fn = handleEdit
          }
        }
        if (el.flag === 2) {
          show = this.type === 'approval' && this.initiateObj.isOpen && this.initiateObj.isEntrust
          fn = handleDialog
        }
        if (el.flag === 3) {
          show = this.type === 'approval' && this.initiateObj.isOpen
          fn = handleDialog
        }
        this.$set(el, 'show', show)
        this.$set(el, 'fn', fn)
      })
    },
    /**
     * 撤回
     */
    handleRevokeFn(params, callback, path) {
      callback(params)
        .then(res => {
          if (res.code === '000000') {
            this.$msgSuccess(res.msg || '操作成功')
            this.$router.push({
              name: path,
              query: { moduleName: this.moduleName }
            })
          }
        })
        .catch(err => {
          console.log('err', err)
          this.$msgError('异常')
        })
    },
    /**
     * 删除
     */
    handleDeleteFn(params, callback, path) {
      callback(params)
        .then(res => {
          if (res.code === '000000') {
            this.$msgSuccess(res.msg || '操作成功')
            this.$router.push({
              name: path,
              query: { moduleName: this.moduleName }
            })
          }
        })
        .catch(err => {
          console.log('err', err)
          this.$msgError('异常')
        })
    },
    handleEditFn(path) {
      this.$router.push({
        name: path,
        query: {
          type: 'edit',
          id: this.id,
          moduleName: this.moduleName
        }
      })
    }
  }
}
